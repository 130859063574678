const border = async () => {
  window.Excel.run(async function (context) {
    // Get the current selection
    var range = context.workbook.getSelectedRange();
    // Add thin borders to each cell
    range.format.borders.getItem("InsideHorizontal").style = "Continuous";
    range.format.borders.getItem("InsideHorizontal").weight = "Thin";
    range.format.borders.getItem("InsideVertical").style = "Continuous";
    range.format.borders.getItem("InsideVertical").weight = "Thin";
    // // Add thick border around the outside
    range.format.borders.getItem("EdgeBottom").style = "Continuous";
    range.format.borders.getItem("EdgeBottom").weight = "Medium";
    range.format.borders.getItem("EdgeTop").style = "Continuous";
    range.format.borders.getItem("EdgeTop").weight = "Medium";
    range.format.borders.getItem("EdgeLeft").style = "Continuous";
    range.format.borders.getItem("EdgeLeft").weight = "Medium";
    range.format.borders.getItem("EdgeRight").style = "Continuous";
    range.format.borders.getItem("EdgeRight").weight = "Medium";
    await context.sync();
    return;
  });
};

export { border };
