const lambdaFunc = async (name, func, desc) => {
  var result = { success: false, msg: "" };
  await window.Excel.run(async (context) => {
    const namedItems = context.workbook.names.load();

    await context.sync();
    let nameCheck = false;
    for (let i = 0; i < namedItems.items.length; i++) {
      const namedItem = namedItems.items[i].name;
      if (namedItem.includes(name)) {
        nameCheck = true;
        result.msg = `The name "${name}" already exists in this spreadsheet. \r\n 
        The custom function may already exist in this spreadsheet or you can try 
        deleting the name "${name}" from Formulas >> Name Manager and try again`;
        result.success = true;
        break;
      }
    }
    if (nameCheck === false) {
      namedItems.add(name, func, desc);
      await context.sync();
      result.msg = `Created the requested custom function ${name}. 
        To use, type "=${name}(....)" into a cell`;
      result.success = true;
    }
    return result;
  });
  return result;
};

const UnitConvLambda = async (type, from, to) => {
  if (
    typeof type !== "string" ||
    typeof from !== "string" ||
    typeof to !== "string" ||
    type === null ||
    from === null ||
    to === null ||
    !unitObj[type][0].includes(from) ||
    !unitObj[type][0].includes(to)
  ) {
    return "Units not found. Please select alternate units and try again.";
  }

  var factor = unitConversion(type, from, to);
  var message = await lambdaFunc(
    `${from}_to_${to}`,
    `=lambda(${from}, ${from} * ${factor})`,
    `Converts ${from} to ${to}`
  );
  if (message.success) {
    return message.msg;
  } else {
    return "Error. Something went wrong";
  }
};

const lengthUnits = ["m", "ft", "in", "yd", "mi"];
//read these arrays as "from Y to X"
//so the first line is from m to m, second value is from m to ft ... etc.
//second line is from ft to m, second value is from ft to ft... etc.
const lengthConvArr = [
  [1, 3.28084, 39.3701, 1.09361, 0.000621371],
  [0.3048, 1, 12, 3.33333, 0.000568182],
  [0.0254, 0.0833333, 1, 0.0277778, 0.000471945],
  [0.9144, 3, 36, 1.05148, 0.000578704],
  [1609.34, 5280, 63360, 1760, 1],
];
const flowUnits = ["gpm", "mgd", "cfs", "mld", "lps"];
const flowConvArr = [
  [1, 0.00144, 0.002228, 0.00545099, 0.630902], //gpm
  [694.444, 1, 1.547, 3.78615, 4.382121], //mgd
  [448.832566, 0.64619, 1, 2.4465755, 28.3168466], //cfs
  [183.453385, 0.26412, 0.40873457, 1, 11.574074], //mld
  [15.8503725, 0.02282, 0.03531467, 0.0864, 1], //l/s
];

const unitObj = {
  length: [lengthUnits, lengthConvArr],
  flow: [flowUnits, flowConvArr],
};

const unitConversion = (type, fromUnit, toUnit) => {
  let fromUnitIndex = unitObj[type][0].indexOf(fromUnit);
  let toUnitIndex = unitObj[type][0].indexOf(toUnit);
  return unitObj[type][1][fromUnitIndex][toUnitIndex];
};

export { lambdaFunc, UnitConvLambda, lengthUnits, flowUnits };
