const uppercase = () => {
  // Loop to cycle through each cell in the specified range.
  window.Excel.run(function (context) {
    let range = context.workbook.getSelectedRange();
    range.load("values");
    return context.sync().then(async function () {
      console.log(range.values);
      var upper = range.values;
      for (var i = 0; i < range.values.length; i++) {
        for (var j = 0; j < range.values[i].length; j++) {
          upper[i][j] = range.values[i][j].toUpperCase();
        }
      }
      range.values = upper;
      return context.sync();
    });
  });
};
const sentenceCase = () => {
  // Loop to cycle through each cell in the specified range.
  window.Excel.run(function (context) {
    let range = context.workbook.getSelectedRange();
    range.load("values");
    return context.sync().then(async function () {
      var sentence = range.values;
      for (var i = 0; i < range.values.length; i++) {
        for (var j = 0; j < range.values[i].length; j++) {
          var str = range.values[i][j];
          str = str.toLowerCase();
          str = str.replace(/(^\w{1}|\.\s*\w{1})/g, function (match) {
            return match.toUpperCase();
          });
          sentence[i][j] = str;
        }
      }
      range.values = sentence;
      return context.sync();
    });
  });
};
const properCase = () => {
  // Loop to cycle through each cell in the specified range.
  window.Excel.run(function (context) {
    let range = context.workbook.getSelectedRange();
    range.load("values");
    return context.sync().then(async function () {
      var properCase = range.values;
      for (var i = 0; i < range.values.length; i++) {
        for (var j = 0; j < range.values[i].length; j++) {
          var str = range.values[i][j];
          str = str.toLowerCase();
          str = str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
          properCase[i][j] = str;
        }
      }
      range.values = properCase;
      return context.sync();
    });
  });
};

const testFunc = async () => {
  await window.Excel.run(async (context) => {
    let range = context.workbook.getSelectedRange();
    range.load("formulas");
    await context.sync();

    console.log(JSON.stringify(range.formulas, null, 4));
    console.log(range);
  });
};

export { uppercase, sentenceCase, properCase, testFunc };
