/* eslint-disable no-unused-vars */
const alternateColors = async (primColor, secColor) => {
  window.Excel.run(async (context) => {
    let range = context.workbook.getSelectedRange();

    range.load(["rowCount", "columnCount"]);
    await context.sync();

    for (let i = 0; i < range.rowCount; i++) {
      var row = range.getRow(i);
      if (i % 2 === 0) {
        row.format.fill.color = primColor;
      } else {
        row.format.fill.color = secColor;
      }
      await context.sync();
    }
  });
};

export { alternateColors };
