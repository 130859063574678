/* eslint-disable no-unused-vars */

async function processRange(
  fillDir,
  pasteValues,
  formulas,
  formats,
  fullCopy,
  endRowNum,
  endColLet
) {
  //set the row or column, depending on selection.
  var sameCol = false;
  var sameRow = false;
  if (fillDir == "Up" || fillDir == "Down") {
    sameCol = true;
    sameRow = false;
  } else if (fillDir == "Left" || fillDir == "Right") {
    sameRow = true;
    sameCol = false;
  }
  try {
    await window.Excel.run(async (context) => {
      //get the currently selected range
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const sourceRange = context.workbook.getSelectedRange();
      sourceRange.load("address, rowIndex, values, formulas");
      await context.sync();

      const currentRowNum = sourceRange.rowIndex + 1; // get current row
      const curr = sourceRange.address; //get full current address
      const address = sourceRange.address.split("!");
      const currentColLet = address[1].match(/[A-Z]+/)[0]; // get current column LETTERS

      // Set the destination to establish the fill/paste range
      if (sameRow) {
        endRowNum = currentRowNum;
      }
      if (sameCol) {
        endColLet = currentColLet;
      }
      const dest = `${endColLet}${endRowNum}`;

      let offsetCurr = null;
      let rngString = null;

      if (fillDir === "Left") {
        offsetCurr = sheet.getRange(curr).getOffsetRange(0, -1);
      } else if (fillDir === "Right") {
        offsetCurr = sheet.getRange(curr).getOffsetRange(0, 1);
      } else if (fillDir === "Up") {
        offsetCurr = sheet.getRange(curr).getOffsetRange(-1, 0);
      } else if (fillDir === "Down") {
        offsetCurr = sheet.getRange(curr).getOffsetRange(1, 0);
      } else {
        offsetCurr = curr;
      }
      offsetCurr.load("address");
      await context.sync();
      rngString = `${offsetCurr.address}:${dest}`;

      const fillRange = sheet.getRange(rngString);

      await context.sync();

      if (pasteValues) {
        sheet.getRange(rngString).copyFrom(
          sourceRange.address,
          window.Excel.RangeCopyType.values,
          true, // skipBlanks
          false // transpose
        );
      } else if (formulas) {
        sheet.getRange(rngString).copyFrom(
          sourceRange.address,
          window.Excel.RangeCopyType.formulas,
          true, // skipBlanks
          false // transpose
        );
      } else if (formats) {
        sheet.getRange(rngString).copyFrom(
          sourceRange.address,
          window.Excel.RangeCopyType.formats,
          true, // skipBlanks
          false // transpose
        );
      } else {
        //fullCopy
        fillRange.copyFrom(
          sourceRange.address,
          window.Excel.RangeCopyType.all,
          true, // skipBlanks
          false // transpose
        );
      }

      await context.sync();
    });
  } catch (error) {
    console.error("Error: " + error.stack);
  }

  return;
}

export { processRange };
