async function dataStore(func, key, value = null, parse = false) {
  try {
    const result = await window.Excel.run(async (context) => {
      const settings = context.workbook.settings;
      if (func === "store") {
        // Store data
        settings.add(key, value);
      } else if (func === "retrieve") {
        // Retrieve data
        const retrievedValue = settings.getItem(key);
        retrievedValue.load("value");
        await context.sync();
        if (parse) {
          return JSON.parse(retrievedValue.value);
        } else {
          return retrievedValue.value;
        }
      } else if (func === "remove") {
        // Remove data
        settings.add(key, "");
      } else {
        console.log("Invalid function call");
      }
      await context.sync();
    });
    return result;
  } catch (error) {
    console.error(
      "Error: " +
        error.stack +
        "\n func: " +
        func +
        " key: " +
        key +
        " value: " +
        value
    );
    return "error";
  }
}

async function getUsedRange() {
  try {
    const result = await window.Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const usedRange = sheet.getUsedRange(true);
      usedRange.load("address");
      await context.sync();

      return usedRange.address;
    });
    return result;
  } catch (error) {
    console.error(error.stack);
    return null;
  }
}

async function getCurrent(what = "address") {
  try {
    const result = await window.Excel.run(async (context) => {
      const sourceRange = context.workbook.getSelectedRange();
      sourceRange.load("address, values, rowIndex, columnIndex");
      await context.sync();
      if (what === "address") {
        return sourceRange.address;
      } else if (what === "values") {
        return sourceRange.values;
      } else if (what === "rowIndex") {
        return sourceRange.rowIndex;
      } else if (what === "columnIndex") {
        return sourceRange.columnIndex;
      } else {
        console.log("Invalid function call");
        return sourceRange.address;
      }
    });
    return result;
  } catch (error) {
    console.error(error.stack);
    return null;
  }
}

function keepLetters(str) {
  return str.replace(/\d/g, "");
}

function keepNumbers(str) {
  return str.replace(/\D/g, "");
}

function columnIndexToLetter(columnIndex) {
  let columnLetter = "";
  let dividend = columnIndex;

  while (dividend > 0) {
    const remainder = (dividend - 1) % 26;
    columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
    dividend = Math.floor((dividend - remainder) / 26);
  }

  return columnLetter;
}

function columnLetterToIndex(columnLetter) {
  let columnIndex = 0;
  let power = 0;

  for (let i = columnLetter.length - 1; i >= 0; i--) {
    const charCode = columnLetter.charCodeAt(i) - 64;
    columnIndex += charCode * Math.pow(26, power);
    power++;
  }

  return columnIndex;
}

export {
  dataStore,
  getUsedRange,
  getCurrent,
  keepLetters,
  keepNumbers,
  columnIndexToLetter,
  columnLetterToIndex,
};
