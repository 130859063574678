<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main m-1 text-center">
    <p>
      <Button label="Length Conversion" @click="customFuncChange(0)" />
    </p>
    <div class="grid" v-if="convUnit[0]">
      <div class="col-6 align-items-center">
        <Dropdown
          v-model="unitInSelect"
          :options="lengthUnits"
          placeholder="Input Unit"
          style="background-color: var(--primary-800)"
        />
      </div>
      <div class="col-6 align-items-center">
        <Dropdown
          v-model="unitOutSelect"
          :options="lengthUnits"
          style="background-color: var(--primary-800)"
          placeholder="Output Unit"
        />
      </div>
    </div>
    <p>
      <Button label="Flow Conversion" @click="customFuncChange(1)" />
    </p>
    <div class="grid" v-if="convUnit[1]">
      <div class="col-6 align-items-center">
        <Dropdown
          v-model="unitInSelect"
          :options="flowUnits"
          placeholder="Input Unit"
          style="background-color: var(--primary-800)"
        />
      </div>
      <div class="col-6 align-items-center">
        <Dropdown
          v-model="unitOutSelect"
          :options="flowUnits"
          style="background-color: var(--primary-800)"
          placeholder="Output Unit"
        />
      </div>
    </div>
    <div>
      <Button
        label="Insert Unit Conversion Function"
        @click="goLambda()"
        severity="info"
        style="min-width: 100%; min-height: 100%"
      />
    </div>

    <p>
      <router-link to="/">
        <Button label="Back to Tools" severity="secondary" />
      </router-link>
    </p>
  </div>
  <LambdaConfirmed />
</template>

<script setup>
import LambdaConfirmed from "../components/LambdaConfirmed.vue";
import { ref } from "vue";
import Dropdown from "primevue/dropdown";
import { UnitConvLambda, lengthUnits, flowUnits } from "../methods/lambda.js";
import { lambdaConfirm } from "@/methods/store";

//describe the types of conversions available
const conversions = ["length", "flow", "volume"];
//at the beginning no conversion units are shown
var convUnit = ref([false, false, false]);
var selConv = ref();
var unitInSelect = ref();
var unitOutSelect = ref();

function customFuncChange(convIndex) {
  //show the selected conversion units and hide the others
  for (let i = 0; i < convUnit.value.length; i++) {
    convUnit.value[i] = false;
  }
  convUnit.value[convIndex] = true;
  selConv.value = conversions[convIndex];
}

async function goLambda() {
  var result = await UnitConvLambda(
    selConv.value,
    unitInSelect.value,
    unitOutSelect.value
  );
  lambdaConfirm.result = result;
  lambdaConfirm.show = true;
}
</script>
