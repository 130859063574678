import { reactive } from "vue";

const colorDialog = reactive({
  show: false,
  primaryColor: "#e6e6e6",
  secondaryColor: "#ffffff",
});

const lambdaConfirm = reactive({
  show: false,
  result: "",
});

const fillMagicSettings = reactive({
  fillDir: "Right",
  pasteValues: false,
  formulas: false,
  formats: false,
  fullCopy: true,
  endRow: "1",
  endCol: "A",
});

const errorMessage = reactive({
  show: false,
  header: "",
  message: "",
});

const gptResponse = reactive({
  value: "",
});

export {
  colorDialog,
  lambdaConfirm,
  fillMagicSettings,
  errorMessage,
  gptResponse,
};
