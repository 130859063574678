<template>
  <div class="main-wrapper">
    <div class="main-container text-center">
      <img alt="Excel Tools logo" src="icon-128.png" />
      <br />
      <router-view />
    </div>
  </div>
</template>

<script setup></script>
